const data = {

    member_list: [
      { id: 1, name: 'Rex', status: 1, status_text: '啟用中', category: 1, category_text: '普通用戶', email: 'rex@innpression.com', tel: '34603350', referral_code: 'JG93F' }
    ],

    admin_list: [
        { id: 1, category: 1, category_text: '員工', username: 'carman', },
        { id: 2, category: 1, category_text: '員工', username: 'edwin', },
        { id: 3, category: 2, category_text: '管理員', username: 'rex_admin', },
    ],
    
    admin_category_list: [
      { id: 1, name: '員工' },
      { id: 2, name: '管理員' },
    ],

  member_status_list: [
    { id: 1, name: '啟用中' },
    { id: 2, name: '停用' },
  ],

  member_approve_status_list: [
    { id: 1, name: '不適用' },
    { id: 2, name: '未審批' },
  ],

  member_category_list: [
    { id: 1, name: '普通用戶' },
    { id: 2, name: '商業用戶' },
  ],


  course_status_list: [
    { id: 1, name: '啟用中' },
    { id: 2, name: '停用' },
  ],

  course_category_list: [
    { id: 1, name: '網頁設計' },
    { id: 2, name: 'UIUX設計' },
    { id: 3, name: '營銷策略' },
  ],

  course_type_list: [
    { id: 1, name: '常規班' },
  ],

  online_course_list: [
    { id: 1, title: '網店開發線上課程-初階', category: 1, category_text: '網頁設計', price: 1980, status: 1, image_url: 'https://firebasestorage.googleapis.com/v0/b/spark-education-cms.appspot.com/o/online%20course%20image.png?alt=media&token=30b6991a-3d0e-47b1-a91c-3992a220e45c', desc: 'This course is targeted for mobile application developers to help them to understand UX/UI (User Experience / User Interface) design concepts and applications, to identify the importance of UX/UI design and to equip them the foundation of design thinking. ' },
  ],

  online_course_module_list: [
    { id: 1, course_id: 1, order: 0, title: '網店開發線上課程-介紹', video_length: '4:10', video_url: 'https://firebasestorage.googleapis.com/v0/b/spark-education-cms.appspot.com/o/online%20course%20image%20video.png?alt=media&token=b4ec33ed-6785-4cac-a2ba-eb53f4743caf&_gl=1*1tmok66*_ga*ODY2NjI2OTg0LjE2Nzk4MzI0Mjk.*_ga_CW55HF8NVT*MTY4NTYzMTQ2Mi40LjEuMTY4NTYzMTQ4MS4wLjAuMA..', },
    { id: 2, course_id: 1, order: 1, title: '網店開發線上課程-第一節', video_length: '5:01', video_url: 'https://firebasestorage.googleapis.com/v0/b/spark-education-cms.appspot.com/o/online%20course%20image%20video.png?alt=media&token=b4ec33ed-6785-4cac-a2ba-eb53f4743caf&_gl=1*1tmok66*_ga*ODY2NjI2OTg0LjE2Nzk4MzI0Mjk.*_ga_CW55HF8NVT*MTY4NTYzMTQ2Mi40LjEuMTY4NTYzMTQ4MS4wLjAuMA..', },
  ],

  face_course_list: [
    { id: 1, title: '網店開發線上課程-初階', category: 1, category_text: '網頁設計', price: 1980, status: 1, image_url: 'https://firebasestorage.googleapis.com/v0/b/spark-education-cms.appspot.com/o/online%20course%20image.png?alt=media&token=30b6991a-3d0e-47b1-a91c-3992a220e45c', desc: 'This course is targeted for mobile application developers to help them to understand UX/UI (User Experience / User Interface) design concepts and applications, to identify the importance of UX/UI design and to equip them the foundation of design thinking. ' },
  ],

  face_course_module_list: [
    { id: 1, course_id: 1, order: 0, title: '網店開發線上課程-介紹', video_length: '4:10', video_url: 'https://firebasestorage.googleapis.com/v0/b/spark-education-cms.appspot.com/o/online%20course%20image%20video.png?alt=media&token=b4ec33ed-6785-4cac-a2ba-eb53f4743caf&_gl=1*1tmok66*_ga*ODY2NjI2OTg0LjE2Nzk4MzI0Mjk.*_ga_CW55HF8NVT*MTY4NTYzMTQ2Mi40LjEuMTY4NTYzMTQ4MS4wLjAuMA..', },
    { id: 2, course_id: 1, order: 1, title: '網店開發線上課程-第一節', video_length: '5:01', video_url: 'https://firebasestorage.googleapis.com/v0/b/spark-education-cms.appspot.com/o/online%20course%20image%20video.png?alt=media&token=b4ec33ed-6785-4cac-a2ba-eb53f4743caf&_gl=1*1tmok66*_ga*ODY2NjI2OTg0LjE2Nzk4MzI0Mjk.*_ga_CW55HF8NVT*MTY4NTYzMTQ2Mi40LjEuMTY4NTYzMTQ4MS4wLjAuMA..', },
  ],

  course_material_list: [
    { id: 1, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p1.png', video_time_start: '0:00', video_time_end: '0:11' },
    { id: 2, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p2.png', video_time_start: '0:12', video_time_end: '0:36' },
    { id: 3, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p3.png', video_time_start: '0:37', video_time_end: '0:57' },
    { id: 4, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p4.png', video_time_start: '0:58', video_time_end: '1:20' },
    { id: 5, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p5.png', video_time_start: '1:21', video_time_end: '1:34' },
    { id: 6, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p6.png', video_time_start: '1:35', video_time_end: '1:59' },
    { id: 7, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p7.png', video_time_start: '2:00', video_time_end: '2:38' },
    { id: 8, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p8.png', video_time_start: '2:39', video_time_end: '3:10' },
    { id: 9, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p9.png', video_time_start: '3:11', video_time_end: '3:34' },
    { id: 10, course_id: 1, module_id: 1, image_url: '', image_name: 'ppt_p10.png', video_time_start: '3:35', video_time_end: '3:49' },
  ],

  timetable_list: [
    {id: 1, report_date: '2023-02-10', username: 'Rex', phone_no: '64814765', status: 1, status_text: '未完成', },
  ],

  timetable_status: [
    { id: 1, name: '未完成' },
    { id: 2, name: '已完成' }
  ],

  coupon_list: [
    { 
      id: 1, 
      category: 1, 
      apply_merchant_amount: 1, 
      discount_type: 1, 
      discount_type_text: '百分比', 
      discount_detail: '9', 
      eff_date_range_text: '2023-03-01 至 2023-06-01',
      start_date: '2023-03-01', 
      end_date: '2023-06-01', 
      discount_code: 'NEWNN',
      repeatable: 1,
      status: 1,
    }
  ],

  coupon_status_list: [
    { id: 1, name: '啟用中' },
    { id: 2, name: '停用' },
  ],

  coupon_repeatable_list: [
    { id: 1, name: '一次性' },
    { id: 2, name: '可重用' },
  ],

  coupon_category_list: [
    { id: 1, name: '管理員' },
    { id: 2, name: '工作坊' },
  ],

  discount_type_list: [
    { id: 1, name: '百分比' },
    { id: 2, name: '價格優惠' },
  ],

  push_noti: { id: 1, target: 1, push_date: '2023-02-20', push_time: '08:00', title: '點擊查看最新優惠', content: '線上課程9折優惠' },

  push_noti_target_list: [
    {
      id: 1, 
      name: '所有人'
    }
  ],

  banner: {
    banner_image_url: 'https://firebasestorage.googleapis.com/v0/b/spark-education-cms.appspot.com/o/banner.png?alt=media&token=8d60c31e-3ce9-4782-853b-2a3fe9bf2998',
    url: 'https://innpression.com/',
  },

  user_group_list: {
    staff: [ '2' ], 
    admin: [ '1', '2', '3', '4' ],
  },

  access_right_list: [
    { id: 1, name: '用戶管理' },
    { id: 2, name: '課程管理' },
    { id: 3, name: '訂單管理' },
    { id: 4, name: '宣傳管理' },
  ],

  setting_list: [
    { id: 1, name: '普通用戶', total_pay_amount: '0', },
    { id: 2, name: 'VIP用戶', total_pay_amount: '10000', },
  ],

  contact_list: [
    {id:1, name: 'Tiffany', phone: '99999999', email: 'tiffany@gmail.com', content: ''},
  ]

}

export default data
