<template>
    <v-form ref="setting-form" @submit.prevent="formSubmit">
        <div>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" class="d-flex">
                            <v-btn @click.prevent="$router.go(-1)" plain>
                                <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
                                <div>上一頁</div>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-card rounded="lg" class="pa-5">
                        <v-card-subtitle><div class="page-title">{{ $route.meta.title }}</div></v-card-subtitle>
                        <v-card-text class="my-5">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <form-control inputType="string" v-model="name" label="會員等級" :required="true" :disabled="isFetching" />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <form-control inputType="number" v-model="total_pay_amount" label="購買金額達到" :required="true" :disabled="isFetching" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn color="primary white--text" class="px-6" x-small :disabled="isFetching" type="submit">儲存</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-form>
 </template>
 
 <script>
 import dummyData from "@/components/dummy-data"
 import { mapActions, mapState } from "vuex";
import FormControl from '@/components/form/FormControl.vue';
import { mdiChevronLeft } from '@mdi/js';
 
 export default {
    name: 'SettingDetail',
    components: {
        FormControl,
    },
    setup() {
        return {
            icons: {
                mdiChevronLeft
            }
        }
    },
    data() {
       return {
            name: null,
            total_pay_amount: 0,
       }
    },
    computed: {
       ...mapState({
          isFetching: (state) => state.request.isFetching,
          isSuccess: (state) => state.request.isSuccess,
          isFail: (state) => state.request.isFail,
       }),

      id: function() {
         return this.$route.params.id;
      },
 
    },
    methods: {
        ...mapActions(["sendRequest"], "request"),
        ...mapActions(["setDialogMessage", "setShowDialog"]),

       async initData() {
          const resp = await this.sendRequest({ url: '', method: 'GET', params: {} });
          if(resp.data) {

            const searchData = dummyData.setting_list.filter(x => x.id == this.id);
            if(searchData.length > 0) {
                const data = searchData[0];
                this.name = data.name;
                this.total_pay_amount = data.total_pay_amount;
            }
          }
       },
        async formSubmit() {
            console.log('save');
            const isValid = this.$refs['setting-form'].validate();
            if(isValid) {
                const formdata = {
                    id: this.id,
                    //...
                };
                const resp = await this.sendRequest({
                url: '', 
                method: 'POST', 
                data: formdata, 
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                });
                console.log('resp', resp);
                if(resp) {
                this.setDialogMessage({ 
                    message: resp.data ? "提交成功" : "提交失敗", 
                    isError: !resp.data, 
                    returnLink: (this.id === undefined ? { name: 'setting' } : null )
                });
                this.setShowDialog(true);
                if(this.id) {
                    await this.initData();
                }
                }
            }
        },
    },
    async mounted() {
        if(this.id) {
            await this.initData();
        }
    }
 }
 </script>
 